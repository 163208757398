html {
    min-height: "100%";
}

body{
    height: "100%";
    background-color: '#f7f7f7';
}

.chordSpan {
    transform: "none !important";
  }

  .pageContainer::-webkit-scrollbar {
    display: none;
  }